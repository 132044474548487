import React, { createContext, useContext } from 'react';
import {
    FeatureFlagKeys,
    FeatureFlags,
    FeatureFlagsProviderProps,
} from './types';

// Create the context with a default value
const FeatureFlagsContext = createContext<FeatureFlags>({});

FeatureFlagsContext.displayName = 'FeatureFlagsContext';

// Provider component
export const FeatureFlagsProvider = ({
    children,
    featureFlags,
}: FeatureFlagsProviderProps) => {
    return (
        <FeatureFlagsContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext);
    if (context === undefined) {
        throw new Error(
            'useFeatureFlags must be used within a FeatureFlagsProvider',
        );
    }
    return context;
};

export const useFeatureFlag = <T extends boolean | number = boolean>(
    flagName: FeatureFlagKeys,
): T | undefined => {
    const featureFlags = useFeatureFlags();
    return featureFlags[flagName] as T;
};
